<template>
  <div>
    <paginate
      :paginate="pagination"
      @change="paginate"
      :showOptions="true"
      :options="paginateOptions"
    />
    <table>
      <thead>
        <tr>
          <th>{{ $t("common.name") }}</th>
          <th>{{ $t("evaluation.averageRating") }}</th>
          <th>{{ $t("evaluation.evaluation") }}</th>
          <th>{{ $t("evaluation.safety") }}</th>
          <th>{{ $t("evaluation.procurement") }}</th>
          <th>{{ $t("evaluation.project") }}</th>
        </tr>
      </thead>
      <tbody>
        <!-- Companies -->
        <tr v-if="companies.length === 0 && loading === true">
          <td class="loader-container" colspan="6"><i class="loader"></i></td>
        </tr>
        <tr v-if="companies.length === 0 && loading === false">
          <td colspan="6">{{ $t("expressions.noData") }}</td>
        </tr>
        <template v-for="(company, companyIndex) in companies">
          <tr
            :key="company.id"
            @click="toggleAgencies(companyIndex)"
            :class="{
              'company-selected': expandedAgencyIndex === companyIndex,
            }"
            class="selectable companies"
          >
            <td>{{ company.name }}</td>
            <td>
              <div style="display: flex">
                <span
                  class="pastille"
                  :class="{
                    red: company.average.score < 3.33,
                    yellow:
                      company.average.score >= 3.33 &&
                      company.average.score < 6.66,
                    green: company.average.score >= 6.66,
                  }"
                ></span>
                <span style="min-width: 20px">{{
                  company.average.score.toFixed(1)
                }}</span>
              </div>
            </td>
            <td>{{ company.average.count }}</td>
            <td>{{ company.details.safety.score.toFixed(1) }}</td>
            <td>{{ company.details.procurement.score.toFixed(1) }}</td>
            <td>{{ company.details.projects.score.toFixed(1) }}</td>
          </tr>

          <!-- Agencies-->
          <template v-if="expandedAgencyIndex === companyIndex">
            <template v-if="loading">
              <tr class="agencies last-agency">
                <td class="loader-container" colspan="6">
                  <i class="loader"></i>
                </td>
              </tr>
            </template>
            <tr v-if="agencies.length > 0" class="subtitle-separator">
              <td class="pl-30" colspan="6">Agences</td>
            </tr>
            <template v-for="(agency, agencyIndex) in agencies">
              <tr
                :key="company.id + '-' + agency.id"
                @click="toggleProjects(agencyIndex)"
                class="agencies selectable"
                :class="{
                  'last-agency': agencyIndex === agencies.length - 1,
                  'agency-selected': expandedProjectIndex === agencyIndex,
                }"
              >
                <td class="pl-30">{{ agency.name }}</td>
                <td>
                  <div style="display: flex">
                    <span
                      class="pastille"
                      :class="{
                        red: agency.average.score < 3.33,
                        yellow:
                          agency.average.score >= 3.33 &&
                          agency.average.score < 6.66,
                        green: agency.average.score >= 6.66,
                      }"
                    ></span>
                    <span style="min-width: 20px">
                      {{ agency.average.score.toFixed(1) }}
                    </span>
                  </div>
                </td>
                <td>{{ agency.average.count }}</td>
                <td>{{ agency.details.safety.score.toFixed(1) }}</td>
                <td>{{ agency.details.procurement.score.toFixed(1) }}</td>
                <td>{{ agency.details.projects.score.toFixed(1) }}</td>
              </tr>
              <!-- Projects -->
              <template v-if="expandedProjectIndex === agencyIndex">
                <template v-if="loadingProject">
                  <tr class="project last-project">
                    <td class="loader-container" colspan="6">
                      <i class="loader"></i>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-if="projects.length > 0" class="subtitle-separator">
                    <td class="pl-60" colspan="6">Projets</td>
                  </tr>
                  <tr
                    v-for="(project, projectIndex) in projects"
                    :key="agency.id + '-' + project.id"
                    class="projects"
                    :class="{
                      'last-project': projectIndex === projects.length - 1,
                    }"
                  >
                    <td class="pl-60">{{ project.name }}</td>
                    <td>
                      <div style="display: flex">
                        <span
                          class="pastille"
                          :class="{
                            red: project.average.score < 3.33,
                            yellow:
                              project.average.score >= 3.33 &&
                              project.average.score < 6.66,
                            green: project.average.score >= 6.66,
                          }"
                        ></span>
                        <span style="min-width: 20px">
                          {{ project.average.score.toFixed(1) }}
                        </span>
                      </div>
                    </td>
                    <td>{{ project.average.count }}</td>
                    <td>{{ project.details.safety.score.toFixed(1) }}</td>
                    <td>
                      {{ project.details.procurement.score.toFixed(1) }}
                    </td>
                    <td>{{ project.details.projects.score.toFixed(1) }}</td>
                  </tr>
                </template>
              </template>
            </template>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import Paginate from "@/components/Paginate";
import PaginationBuilder from "@/lib/Paginate";
import axios from "axios";

export default {
  name: "EvaluationTable",
  components: {
    Paginate,
  },
  props: {
    fetch: Function,
    singleSelect: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 10,
    },
    paginateOptions: {
      type: Array,
      default: () => [50, 100, 200],
    },
    identifier: {
      type: String,
      default: "id",
    },
    order: {
      type: String,
      default: "ASC",
    },
    paramsFilters: {
      type: String,
      default: "start_date=&end_datethis.end_date&companies=&agencies=",
    },
  },
  data() {
    return {
      loading: false,
      internalColumns: [],
      hasFilters: false,
      companies: [],
      agencies: [],
      projects: [],
      start_date: "2000-01-01",
      end_date: new Date().toISOString().slice(0, 10),
      pagination: {
        page: 1,
        total: 0,
        size: this.size,
        sort: {
          column: this.identifier,
          direction: this.order,
        },
      },
      selectAll: false,
      selected: [],
      API: axios.defaults.baseURL,
      authToken: this.$store.getters["auth/getToken"],
      emptyTableMessage: this.customEmptyTableMessage,
      tableSummary: null,
      expandedAgencyIndex: null,
      expandedProjectIndex: null,
      companyIndex: null,
      agencyIndex: null,
      projectIndex: null,
      loadingProject: false,
    };
  },
  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.pagination.sort.column = this.identifier;
      this.pagination.sort.direction = this.order;
      this.refreshWithFilters(this.paramsFilters);
    },

    refreshWithFilters(paramsFilters) {
      const p = new PaginationBuilder(this.preparePaginationObject());
      this.loading = true;
      this.fetch(p, paramsFilters)
        .then((data) => {
          this.callback(data);
        })
        .catch(this.fetchError)
        .finally(() => {
          this.$nextTick(() => {
            this.loading = false;
          });
        });
    },
    callback(data) {
      this.pagination.total = data.totalElements;
      this.companies = Array.isArray(data.content) ? data.content : [];
      this.emptyTableMessage = this.$t("expressions.noData");
      this.tableSummary = data.summary || null;
    },
    preparePaginationObject() {
      return {
        ...this.pagination,
        page: this.pagination.page - 1,
        sort: {
          ...this.pagination.sort,
        },
        filters: [],
      };
    },
    paginate(p) {
      this.pagination = p;
      this.expandedAgencyIndex = null;
      this.refreshWithFilters(this.paramsFilters);
    },
    toggleAgencies(index) {
      this.expandedProjectIndex = null;
      if (this.expandedAgencyIndex === index) {
        this.expandedAgencyIndex = null;
        this.agencies = [];
      } else {
        this.expandedAgencyIndex = index;
        this.getAgenciesEvaluations(this.companies[index].id);
      }
    },
    toggleProjects(agencyIndex) {
      if (this.expandedProjectIndex === agencyIndex) {
        this.expandedProjectIndex = null;
        this.projects = [];
      } else {
        this.expandedProjectIndex = agencyIndex;
        if (this.agencies[agencyIndex]) {
          this.getProjectsEvaluations(this.agencies[agencyIndex].id);
        } else {
          console.error("No agency found for this index.");
        }
      }
    },

    getAgenciesEvaluations(companyId) {
      this.loading = true;
      this.$api
        .fetchAgenciesEvaluations(companyId, this.paramsFilters)
        .then((response) => {
          this.agencies = response;
        })
        .catch((error) => {
          console.error("Error fetching agency evaluations:", error);
          this.agencies = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getProjectsEvaluations(agencyId) {
      this.loadingProject = true;
      this.$api
        .fetchAgenciesEvaluations(agencyId, this.paramsFilters)
        .then((response) => {
          this.projects = response;
        })
        .catch((error) => {
          console.error("Error fetching agency evaluations:", error);
          this.projects = [];
        })
        .finally(() => {
          this.loadingProject = false;
        });
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

thead {
  background-color: #f4f4f4;
}

th,
td {
  padding: 10px;
  text-align: left;
}

tr {
  border-bottom: 1px solid #ddd;
}

tr.company-selected {
  background-color: #01b4dd;
  border: solid 1px #01b4dd;
  color: white;
}

.companies:hover {
  background: #01b4dd;
  color: white;
}

tr.agency-selected {
  background-color: #018aab;
  border-left: solid 1px #01b4dd;
  border-right: solid 1px #01b4dd;
  border-bottom: none;
  color: white;
}

tr.agencies {
  border-left: solid 1px #01b4dd;
  border-right: solid 1px #01b4dd;
}

.agencies:hover {
  background-color: #018aab;
  color: white;
}

.subtitle-separator {
  line-height: 7px;
  background-color: #f68121;
  color: white;
  border-left: solid 1px #01b4dd;
  border-right: solid 1px #01b4dd;
  border-top: none;
  border-bottom: none;
}

.pl-30 {
  padding-left: 30px;
}

.pl-60 {
  padding-left: 60px;
}

tr.projects {
  border-left: solid 1px #01b4dd;
  border-right: solid 1px #01b4dd;
}

tr.agencies.last-agency {
  border-bottom: solid 1px #01b4dd;
}

.selectable {
  cursor: pointer;
}

.pastille {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 20px;
}

.red {
  background-color: #db3912;
}
.yellow {
  background-color: #fe9a01;
}
.green {
  background-color: #0e961b;
}

.loader-container {
  font-size: 30px;
  text-align: center;
  background-color: #fff;
  .loader {
    margin: 0 auto;
  }
}
</style>
